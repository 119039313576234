module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@3.20.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@4.25.8_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/bootutils/www.jotai.com.cn/website","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.8_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.4.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
